body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #3b53da;
}

/* body {
  overflow-x: hidden;
} */

body {
  transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
